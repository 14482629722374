export default {
  name: 'Fortitude fitness',
  // gymId: '5fd7cff72eb93d371e0aa7de',
  email: 'support@fortitudefitness.uk',
  styles: {
    fontFamily: 'Montserrat',
    primary: '#414142',
    secondary: '#002e24',
    accent: '#939799',
    accentDark: '#414142'
  },
  socialMedia: [
    {
      name: 'facebook',
      url: 'https://www.facebook.com/Team.Fortitude.Fitness.UK'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/team_fortitude_fitness/'
    }
  ],
  googleMaps: { lat: 53.9592279, lng: -1.0781796 },
  isHomepageHeroDark: true,
  locationsPage: {
    showUpcomingEvents: false,
    upcomingEventsClassTypes: ['class', 'induction']
  }
};
