import { useContext } from 'react';
import Button from '../../../components/UI/Button/Button';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../../../context/App/AppContext';

const Homepage = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AppContext);
  const hero = (
    <img
      id="hero-img"
      className="z-0 object-cover w-screen  h-[calc(100vh-80px)]"
      src={require('../../assets/hero-image.jpg')}
      alt="hero"
    />
  );

  const loginSignUpCard = (
    <div className="absolute mx-[30px] w-[calc(100%-100px)] md:right-10 bottom-[125px]  md:w-2/6 lg:w-1/3 p-8 bg-primary text-white">
      <div className="text-2xl pb-2 border-b-2 border-white mb-4">Sign up!</div>
      <div className="mb-8">
        If you have already signed up then please log in below, for details on signing up please contact us.
      </div>
      <Button
        label={isAuthenticated ? 'dashboard' : 'login'}
        onClick={() => navigate(isAuthenticated ? `/gym` : `/login`)}
        secondary
        fullWidth
      />
    </div>
  );

  return (
    <div className="homepage_header h-[calc(100vh-80px)] realtive">
      {hero}
      {loginSignUpCard}
    </div>
  );
};

export default Homepage;
