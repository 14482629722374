import Title from '../../../components/UI/Title/Title';
const PrivatePolicyPage = () => (
  <div className="body-container text-justify">
    <Title title={'PRIVACY POLICY'} />
    <div>
      Fortitude Fitness and Training Limited ("we" and "us") respect the privacy of our users ("you"), therefore have
      developed the following Privacy Policy to show our commitment to protecting your privacy. If you have any
      questions about this Privacy Policy please do not hesitate to contact us on 0161 523 2598
    </div>

    <div className="sectionTitle">1. INTRODUCTION AND DEFINITIONS</div>

    <ol className={'oList'}>
      <li key={0}>This is the privacy policy referred to in the Terms and Conditions</li>
      <li>The term “Website” means the website ‘https://fortitudefitness.uk’</li>
      <li>Other defined terms shall have the meanings given to them in the Terms of Use</li>
      <li>
        The terms “Data Controller” and “Personal Data” shall have the meanings given to them in the Data Protection Act
        1998 (the “Act”).
      </li>
      <li>
        For the purposes of the Act, the Data Controller shall be Fortitude Fitness and Training Limited (company number
        15093277), registered address 651a Mauldeth Road West, Chorlton Cum Hardy, Manchester, United Kingdom, M21 7SA
      </li>
      <li>
        This privacy policy (together with our terms of and any other documents referred to in it) sets out the basis on
        which any Personal Data we collect from you, or that you provide to us, will be processed by us.
      </li>
      <li>
        Please read the following carefully to understand our views and practices regarding your Personal Data and how
        we will treat it. By visiting the Website, you are accepting and consenting to the practices described in this
        policy. If you have any questions at all about this privacy policy or the Website, please email
        support@fortitudefitness.uk
      </li>
      <li>Our nominated representative for the purpose of the Act is Ms Jenny Cromack.</li>
    </ol>

    <div className="sectionTitle">2. INFORMATION WE COLLECT FROM YOU</div>

    <ol className={'oList'}>
      <li>We will collect and process the following Personal Data (and other data):</li>
      <ul className={'uList'}>
        <li>
          <strong>Information you give us.</strong> This is information about you that you give us by filling in forms
          on the Website (including health questionnaires which may include sensitive personal data) or by corresponding
          with us by phone, e-mail or otherwise. It includes information you provide when you register to use the
          Website, subscribe to our service, search for a product, place an order on the Website, participate in
          discussion boards or other social media functions on our site, enter a competition, promotion or survey, click
          and use links provided throughout, use and engage with the website in any way and when you report a problem
          with the Website. The information you give us may include your name, address, e-mail address and phone number,
          financial and credit card information, personal description and photograph;
        </li>
        <li>
          <strong>Information we collect about you.</strong> With regard to each of your visits to the Website we will
          automatically collect the following information:
        </li>

        <ul className={'uList'}>
          <li>
            technical information, including the Internet protocol (IP) address used to connect your computer or
            alternative device to the Internet, your login information, browser type and version, time zone setting,
            browser plug-in types and versions, operating system and platform; and
          </li>
          <li>
            information about your visit, including the full Uniform Resource Locators (“URL”), clickstream to, through
            and from the Website (including date and time), products you viewed or searched for’ page response times,
            download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks,
            and mouse-overs), methods used to browse away from the page, and any phone number used to call our customer
            service number.
          </li>
        </ul>

        <li>
          <strong>Information we receive from other sources.</strong> This is information we receive about you if you
          use any of the other websites we operate or the other services we provide. We are working closely with third
          parties (including, for example, business partners, sub-contractors in payment and delivery services). We will
          notify you when we receive information about you from them and the purposes for which we intend to use that
          information.
        </li>
      </ul>
      <li>The term “Website” means the website ‘https://fortitudefitness.uk’</li>
      <li>Other defined terms shall have the meanings given to them in the Terms of Use</li>
      <li>
        The terms “Data Controller” and “Personal Data” shall have the meanings given to them in the Data Protection Act
        1998 (the “Act”).
      </li>
      <li>
        For the purposes of the Act, the Data Controller shall be Fortitude Fitness and Training Limited (company number
        15093277), registered address 651a Mauldeth Road West, Chorlton Cum Hardy, Manchester, United Kingdom, M21 7SA
      </li>
      <li>
        This privacy policy (together with our terms of and any other documents referred to in it) sets out the basis on
        which any Personal Data we collect from you, or that you provide to us, will be processed by us.
      </li>
      <li>
        Please read the following carefully to understand our views and practices regarding your Personal Data and how
        we will treat it. By visiting the Website, you are accepting and consenting to the practices described in this
        policy. If you have any questions at all about this privacy policy or the Website, please email
        support@fortitudefitness.uk
      </li>
      <li>Our nominated representative for the purpose of the Act is Ms Jenny Cromack.</li>
    </ol>
  </div>
);
export default PrivatePolicyPage;
