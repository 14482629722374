import { QrReader } from 'react-qr-reader';

export default function ClientProfileAddQr({ handleQrCodeScan, handleQrCodeError }) {
  return (
    <QrReader
      containerStyle={{ width: '150px' }}
      scanDelay={2000}
      onResult={(result, error) => {
        if (!!result) {
          return handleQrCodeScan;
        }

        if (!!error) {
          return handleQrCodeError;
        }
      }}
    />
  );
}
