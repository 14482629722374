import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Title from '../../components/UI/Title/Title';
import DefaultClientUpcomingClasses from '../Timetables/DefaultClientUpcomingClasses/DefaultClientUpcomingClasses';
import TilesBoard from '../../components/UI/TilesBoard/TilesBoard';
import { menuItemsArray } from '../../components/Navigation/MenuNavigation/menuItems';
import BirthdayNotificationModal from '../../components/BirthdayNotification/BirthdayWishesModal';
import { AppContext } from '../../context/App/AppContext';
import ClientStatusNotification from './ClientStatusNotification/ClientStatusNotification';
import AcceptStatements from '../AcceptStatements/AcceptStatements';
import { hasUserSubscribedToMembershipPlan } from '../../shared/userFunctions';
import ManageMembershipPlans from '../ManageMembershipPlans/ManageMembershipPlans';
import ClientClassesLeft from './ClientClassesLeft/ClientClassesLeft';
import Loading from '../../components/Loading/Loading';

//TODO: add logic when MB user has inactive membership or didn;t choose
const ClientDashboard = () => {
  const { userData, selectedGym, selectedGymIsLoading } = useContext(AppContext);
  const location = useLocation();
  const tiles = menuItemsArray(userData, selectedGym?.settings, location.pathname, null, 'showAsTile');
  if (!userData.areTermsAndConditionsAccepted) {
    return (
      <div className={'body-container'}>
        <AcceptStatements />
      </div>
    );
  }

  if (selectedGymIsLoading) {
    return <Loading />;
  }

  return (
    <div className={'body-container'}>
      <BirthdayNotificationModal />

      {userData.isActive ? (
        <>
          <div className="titleAndBtnContainer">
            <Title title={'Your dashboard'} subtitle={'Strive for progress, not perfection'} />
            <ClientClassesLeft />
          </div>

          <DefaultClientUpcomingClasses />
          <TilesBoard tiles={tiles} />
        </>
      ) : userData.isMembershipUser && !hasUserSubscribedToMembershipPlan(userData) ? (
        <ManageMembershipPlans />
      ) : (
        <ClientStatusNotification />
      )}
    </div>
  );
};

export default ClientDashboard;
