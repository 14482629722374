import moment from 'moment';
import { returnClassesDetails } from './classFunctions';
export const isValueCorrect = (value) => value !== undefined && value !== null;
export const isUser = (userData) => isValueCorrect(userData) && userData.role === 'user';
export const isAdmin = (userData) => isValueCorrect(userData) && userData.role === 'admin';
export const isTrainer = (userData) => isValueCorrect(userData) && userData.role === 'trainer';
export const isAdminOrTrainer = (userData) => isValueCorrect(userData) && (isAdmin(userData) || isTrainer(userData));

export const isMembershipPaid = (userData) =>
  isUser(userData) &&
  isMembershipUser(userData) &&
  userData.joiningFeePaid &&
  userData.currentMembershipPlan &&
  moment().isSameOrBefore(moment(userData.currentMembershipPlan.current_period_end, 'YYYY-MM-DD hh:mm:ss'));
export const isActiveMembershipUser = (userData) =>
  isUserStatusActive(userData) && userData.areTermsAndConditionsAccepted && isMembershipPaid(userData);

export const hasUserSubscribedToMembershipPlan = (userData) => userData.currentMembershipPlan;
export const isMembershipUser = (userData) => isUser(userData) && userData.customerType === 'full-member';
export const isClassPassUser = (userData) => isUser(userData) && userData.customerType === 'class-pass-member';
export const isActiveClassPassUser = (userData) =>
  isClassPassUser(userData) && userData.status === 'active' && userData.areTermsAndConditionsAccepted;
export const isActiveUser = (userData) => isActiveMembershipUser(userData) || isActiveClassPassUser(userData);
export const isUserStatusActive = (userData) => isUser(userData) && userData.status === 'active';

export const isUnlimitedMembershipUser = (userData) =>
  isActiveMembershipUser(userData) && userData.currentMembershipPlan.ssPlanId.unlimited;
export const isFullAccessAdminOrTrainer = (userData) =>
  isAdminOrTrainer(userData) && (userData['gymsFullAccess'] === undefined || userData.gymsFullAccess);

export const isFullAccessAdmin = (userData) => isAdmin(userData) && userData.gymsFullAccess;

export const returnGymsByAccess = (gymsList, userData) => {
  let result = [];
  if (isFullAccessAdminOrTrainer(userData)) {
    return gymsList;
  }

  if (gymsList !== undefined && gymsList !== null) {
    gymsList.forEach((elem) => {
      userData.gymId.forEach((gym) => {
        if (elem._id === gym) {
          result.push(elem);
        }
      });
    });
  }
  return result;
};

export const isMembershipPlanActive = (userData) =>
  isUser(userData) &&
  moment(userData.currentMembershipPlan.current_period_end, 'YYYY-MM-DD').isSameOrAfter(moment()) &&
  userData.joiningFeePaid;

export const returnTrainersForSelectedGym = (trainersList, gymId) =>
  trainersList.filter((trainer) => isFullAccessAdminOrTrainer(trainer) || trainer.gymId.includes(gymId));

export const returnUserClassesLeftAmount = (classType, userData, selectedClass) => {
  if (classType === 'class' && userData.customerType === 'full-member') {
    if (
      userData.currentMembershipPlan &&
      userData.currentMembershipPlan.current_period_end &&
      userData.currentMembershipPlan.ssPlanId &&
      userData.currentMembershipPlan.ssPlanId.unlimited &&
      moment(userData.currentMembershipPlan.current_period_end).isSameOrAfter(moment()) &&
      userData.currentMembershipPlan.ssPlanId.unlimited
    ) {
      return 100;
    }
    return moment(selectedClass.classDate).format('M') - moment().format('M') === 0
      ? userData.classesLeft
      : userData.classesLeftNextMonth;
  }
  return userData[returnClassesDetails(classType).usersClassesLeft];
};
