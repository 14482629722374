import Icon from '../../../components/UI/Icon/Icon';
import Title from '../../../components/UI/Title/Title';
import companyDetails from '../../companyDetails';
import { Link } from 'react-router-dom';
import GoogleMaps from '../../../components/GoogleMaps/GoogleMaps';

export default () => (
  <div className={'body-container '}>
    <Title title="Contact us" />
    <div className={'md:flex justify-between'}>
      <div>
        <div className="font-bold md:grid md:grid-cols-2 md:w-3/4 gap-4 text-md">
          <div>Mail:</div>
          <div className="text-secondary mb-6 md:mb-0">
            <a href={`mailto:${companyDetails.email}`}>{companyDetails.email}</a>
          </div>
          <div>Address:</div>
          <div className="mb-6 md:mb-0">
            Fortitude Fitness and Training Limited <br />
            651a Mauldeth Road West <br />
            Chorlton Cum Hardy, Manchester, <br />
            United Kingdom, M21 7SA <br />
          </div>
          <div>Telephone number:</div>
          <div>01615232598</div>
        </div>

        <div className={'mt-4 md:grid md:grid-cols-2 gap-4'}>
          <div>
            <div className={'text-secondary text-4xl font-bold'}>Bolton</div>
            <div className={'font-bold'}>
              Avenue Street <br />
              Bolton <br />
              BL1 3AW
              <br />
              <br />
              Queens Park BL1 4RU
            </div>
          </div>

          <div>
            <div className={'text-secondary text-4xl font-bold'}>Leeds</div>
            <div className={'font-bold'}>
              The Hangar
              <br />
              Roundhegians RC
              <br />
              LS17 6RT <br />
              <br />
              Roundhay Park <br />
              LS8 1DQ <br />
            </div>
          </div>
          <div>
            <div className={'text-secondary text-4xl font-bold'}>YORK</div>
            <div className={'font-bold'}>
              Rawcliffe ParK
              <br />
              YO30 5XZ
              <br />
            </div>
          </div>
        </div>

        <div className="flex gap-x-3 my-14">
          {companyDetails.socialMedia.map(({ name, url }) => (
            <Link key={name} to={url} target="_blank" rel="noopener noreferrer">
              <Icon name={name} size={40} color={companyDetails.secondary} />
            </Link>
          ))}
        </div>
      </div>

      <GoogleMaps />
    </div>
  </div>
);
